﻿export const dataAlpha = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:40:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:40:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '16:30:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataBeta = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:00:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '16:30:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataChi = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:30:00 a1/p1',
      'End Time': '13:40:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '13:40:00 a1/p1',
      'End Time': '14:10:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '14:10:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataDelta = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '14:10:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '14:10:00 a1/p1',
      'End Time': '14:40:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '14:40:00 a1/p1',
      'End Time': '16:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataGamma = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:00:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataIota = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '11:30:00 a1/p1',
      'End Time': '12:10:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '12:10:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '16:30:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataKappa = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:30:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:20:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '16:20:00 a1/p1',
      'End Time': '17:50:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataLambda = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:10:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '13:10:00 a1/p1',
      'End Time': '13:40:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:40:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:20:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '16:20:00 a1/p1',
      'End Time': '17:50:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataNu = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '12:40:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '12:40:00 a1/p1',
      'End Time': '13:10:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:10:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:20:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '16:20:00 a1/p1',
      'End Time': '17:50:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataOmega = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '13:30:00 a1/p1',
      'End Time': '14:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '14:00:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:10:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '16:20:00 a1/p1',
      'End Time': '17:40:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataOmicron = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:30:00 a1/p1',
      'End Time': '13:50:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '13:50:00 a1/p1',
      'End Time': '14:20:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '14:20:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '16:30:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataPhi = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:30:00 a1/p1',
      'End Time': '14:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '14:00:00 a1/p1',
      'End Time': '14:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '14:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:10:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '16:20:00 a1/p1',
      'End Time': '17:40:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataPi = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '11:30:00 a1/p1',
      'End Time': '12:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '12:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataPsi = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '12:50:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '12:50:00 a1/p1',
      'End Time': '13:20:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:20:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataRho = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:10:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '11:10:00 a1/p1',
      'End Time': '12:40:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:40:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '16:30:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataSigma = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '13:20:00 a1/p1',
      'End Time': '13:50:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:50:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '16:30:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataTau = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:10:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '13:10:00 a1/p1',
      'End Time': '13:40:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:40:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '16:30:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataTheta = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '14:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '14:20:00 a1/p1',
      'End Time': '14:50:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '14:50:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataUpsilon = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:40:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '11:40:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '16:10:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '16:20:00 a1/p1',
      'End Time': '17:40:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};
export const dataZeta = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN & MEET YOUR F!ROSH GROUP',
      'Start Time': '7:30:00 a1/p1',
      'End Time': '8:30:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Description':
        'Our kickoff the frosh week, and introduction to all things SKULE™. We all gather in to Convocation Hall',
      'Start Time': '8:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '11:00:00 a1/p1',
      'End Time': '11:20:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '11:20:00 a1/p1',
      'End Time': '12:20:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'D!YE STATION',
      'Event Description':
        'When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '12:20:00 a1/p1',
      'End Time': '12:50:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'F!ROSH GAMES',
      'Event Description':
        'Compete against other frosh groups in the craziest competitions imaginable',
      'Start Time': '12:50:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30:00 a1/p1',
      'End Time': '10:30:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'BUILD BATTLE',
      'Event Description':
        "Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '11:00:00 a1/p1',
      'End Time': '12:30:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30:00 a1/p1',
      'End Time': '13:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '13:30:00 a1/p1',
      'End Time': '15:00:00 a1/p1',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOURS',
      'Event Description':
        'Where our Frosh leedurs will lead you through a tour of campus to find all the best places to study, work on projects, and nap!',
      'Start Time': '15:00:00 a1/p1',
      'End Time': '18:00:00 a1/p1',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '18:00:00 a1/p1',
      'End Time': '18:30:00 a1/p1',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
};

// for home page
export const data = {
  'MONDAY AUGUST 26': [
    {
      'Event Name': 'CHECK-IN AND MEET WITH GROUP',
      'Start Time': '7:30 AM',
      'Event Location': 'GB Front Steps',
      'End Time': '8:30 AM',
      Color: 'purple',
    },
    {
      'Event Name': 'MATRICULATION',
      'Event Location': 'CONVOCATION HALL',
      'Event Description':
        'Our kickoff to F!rosh Week, and an introduction to all things Skule! Hear from clubs, design teams, and other student groups about all the amazing opportunities waiting for you here at UofT Engineering, while also learning about some of our many Skule traditions!',
      'Start Time': '8:30 AM',
      'End Time': '10:30 AM',
      Color: 'yellow',
    },
    {
      'Event Name': 'F!ROSH GAMES + D!YE STATION',
      'Event Description':
        'Frosh Games: Compete against other frosh groups in the craziest competitions imaginable.<br />Dye: When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '10:30 AM',
      'End Time': '12:30 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30 PM',
      'End Time': '1:30 PM',
      Color: 'green',
    },
    {
      'Event Name': 'F!ROSH GAMES + D!YE STATION',
      'Event Description':
        'Frosh Games: Compete against other frosh groups in the craziest competitions imaginable.<br />Dye: When I say purple you say purple! Where F!rosh and Leedurs alike dye their bodies (or parts thereof) purple. The colour purple represents great significance in the traditions of engineering schools across Canada.',
      'Start Time': '1:30 AM',
      'End Time': '3:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'DOWNTOWN WALKAROUND',
      'Event Description':
        'Join us for a lovely tour of Toronto’s downtown with 1000 of your newest friends and classmates',
      'Start Time': '3:00 PM',
      'End Time': '6:00 PM',
      Color: 'green',
    },
    {
      'Event Name': 'UNWIND NIGHT',
      'Event Location': 'GB303',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'TUESDAY AUGUST 27': [
    {
      'Event Name': 'MEET WITH YOUR GROUP',
      'Event Location': 'Front Campus',
      'Start Time': '8:15:00 a1/p1',
      'End Time': '8:45:00 a1/p1',
      Color: 'purple',
    },
    {
      'Event Name': 'CONSENT WORKSHOP',
      'Start Time': '9:30 AM',
      'End Time': '10:30 AM',
      'Event Description':
        'If you attend the consent workshop, you can be entered to win amazing prizes, ranging from $50 to $1000, including a tablet and a laptop!',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOUR & BUILD BATTLE',
      'Event Description':
        "Campus Tour: Be guided by your F!rosh Leedurs on a tour of our gorgeous campus, showing you all the best places to study, grab food, participate in extracurriculars, and nap! <br />Build Battle: Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '11:00 AM',
      'End Time': '12:10 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'LUNCH',
      'Start Time': '12:30 PM',
      'End Time': '1:30 PM',
      Color: 'green',
    },
    {
      'Event Name': 'ACADEMIC SUCCESS SEMINAR',
      'Event Description':
        "Learn from some of your amazing new TA's about all the strategies you need to know for success here at UofT Engineering!",
      'Start Time': '1:30 PM',
      'End Time': '3:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': 'CAMPUS TOUR & BUILD BATTLE',
      'Event Description':
        "Campus Tour: Be guided by your F!rosh Leedurs on a tour of our gorgeous campus, showing you all the best places to study, grab food, participate in extracurriculars, and nap! <br />Build Battle: Your first of many design challenges at UofT Engineering, except its probably the one of the coolest ones you do! Work with your frosh group to tackle your piece of the puzzle for this year's unique design challenge, coming together to build something truly amazing as a 2T8 class!",
      'Start Time': '3:00 PM',
      'End Time': '6:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'CHEER OFF',
      'Start Time': '6:00 PM',
      'End Time': '6:30 PM',
      Color: 'green',
    },
    {
      'Event Name': 'TOTES & TRIVIA',
      'Event Location': 'GB303',
      'Start Time': '7:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'WEDNESDAY AUGUST 28': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'SKULE™ HUNT',
      'Event Description':
        'SKULE™ HUNT: The longest items list you’ve ever seen. Join us for a full fledged scavenger hunt all over the city of Toronto!',
      'Start Time': '5:00 PM',
      'End Time': '11:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'THURSDAY AUGUST 29': [
    {
      'Event Name': 'FACULTY EVENTS',
      'Event Description': `Check out more information about Faculty Events here: <a href='https://undergrad.engineering.utoronto.ca/event/faculty-orientation-events/'>Faculty Events</a>`,
      'Start Time': ' ',
      'End Time': ' ',
      Color: 'gray',
    },
    {
      'Event Name': 'CLUBS FAIR',
      'Event Location': 'Bahen Lobby',
      'Event Description':
        'Interested in joining a club, intramural sport or getting involved with the Engineering Society (EngSoc)? Attend the Engineering Clubs Fair to learn more about the 100+ ways you can get involved.</a>.',
      'Start Time': '11:00 AM',
      'End Time': '2:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': 'COMMON ROOM GAME NIGHT',
      'Event Description':
        "Sign up for game night <a href='https://docs.google.com/forms/d/e/1FAIpQLSfDGU1ejsC8bIwtHwCWC0YiEIULjcpsCm4yJ3x2cRvwCBIA7Q/viewform'>here</a>!",
      'Event Location': 'MB225',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'dark-purple',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'FRIDAY AUGUST 30': [
    {
      'Event Name': 'F!ROSH OLYMPIKS',
      'Start Time': '10:00 AM',
      'End Time': '5:00 PM',
      Color: 'purple',
    },
    {
      'Event Name': 'SKULE’S GOT TALENT',
      'Event Description':
        "Sign up for the talent show <a href='https://docs.google.com/forms/d/e/1FAIpQLSf4O3uIvwVlFQ-kuobHp_OUWwPA9f7kfUvzSWhrBMv_jQqgZg/viewform'>here</a>!",
      'Event Location': 'GB Front Steps',
      'Start Time': '6:00 PM',
      'End Time': '9:00 PM',
      Color: 'yellow',
    },
    {
      'Event Name': '****',
      'Event Location': 'The Pit',
      'Start Time': '6:00 PM',
      'End Time': '11:00 PM',
      Color: 'purple',
    },
  ],
  'SATURDAY AUGUST 31': [
    {
      'Event Name': 'F!ROSH RETREAT',
      'Event Description':
        'Come with us out of the concrete jungle to enjoy a weekend of camp nostalgia, filled with swimming, games, campfires and more!',
      'Start Time': '10:00 AM',
      'End Time': 'Late',
      Color: 'purple',
    },
  ],
  'SUNDAY SEPTEMBER 1': [
    {
      'Event Name': 'F!ROSH RETREAT',
      'Event Description':
        'Come with us out of the concrete jungle to enjoy a weekend of camp nostalgia, filled with swimming, games, campfires and more!',
      'Start Time': 'Early',
      'End Time': '2:00 PM',
      Color: 'purple',
    },
  ],
};
