import oc1Photo from '../../assets/about/execs/Lily_pfp.png';
import oc2Photo from '../../assets/about/execs/vedant_pfp.webp';
import vcFinance from '../../assets/about/execs/finance_pfp.png';
import vcEvents1 from '../../assets/about/execs/vc-events_pfp.png';
import vcEvents2 from '../../assets/about/execs/events_pfp.png';
import vcLeadership from '../../assets/about/execs/leadership_pfp.png';
import vcMarketing from '../../assets/about/execs/marketing_pfp.png';

export const execInfo = {
  ocs: [
    {
      name: 'LILY VANDERWOUDE',
      role: 'Orientation Chair',
      discipline: 'Mech 2T5 + PEY ',
      image: oc1Photo,
      description: `Some fun facts about me are: I’m a Mech 2T5 + PEY (previously T1!) specialized in the Mechatronics 
    & Solid Mechanics streams 😎. I worked as a full-time librarian during my gap year before uni 🤓 (some of you 
    may recall story time with Lily during Godiva Week 😌). I am a nature-enthusiast who enjoys rock climbing 🧗‍♀️ & 
    camping 🏕️ & hiking 🌿. My brother and I raised money for the Canadian Cancer Society to shave our heads in 2022 
    for my aunt fighting cancer (note the range of hairstyles 🫢). I was Vice Chair Community for F!rosh Week 2T3, a 
    2T2 Alpha Leedur & I cannot wait to be your Co-OC 2T4!! 🥹

    `,
    },

    {
      name: 'VEDANT GUPTA',
      role: 'Orientation Chair',
      discipline: 'EngSci (Aerospace) 2T5 + PEY',
      image: oc2Photo,
      description: `Some fun facts about me are: I play the trumpet (among many other instruments. I have a black belt 
    in taekwondo (woah). You’ll probably have seen me around in a variety of things including but not limited to: UTFR, 
    Skulenite, Engineering Outreach Office, BnG Contest during Godiva Week, etc. I have lived in 6 countries since I was 
    born. I am an avid watcher and supporter of formula one (diehard McLaren fan, don’t come at me, Lando first win this year)
    `,
    },
  ],

  vcs: [
    {
      name: 'MEHAK AFZAL',
      role: 'VC Finance',
      discipline: ' Mech 2T5 + PEY',
      image: vcFinance,
      description: `Some fun facts about me are: You may have (hopefully) seen me around skule in a variety of 
      activities ranging from club events by WISE, UTEK and UTESCA to Design teams and being an Engineering E-buddy. 
      Apart from skule, which is hard to detach from, I enjoy playing tennis (though I’m still a rookie), watching 
      K-dramas, sipping on boba, delving into philosophy, and indulging in plenty of coffee (it’s a lifestyle).

      `,
    },

    {
      name: 'OLIVIA FREDRICKSON',
      role: 'VC Leadership',
      discipline: 'Mech 2T4+1 + PEY',
      image: vcLeadership,
      description: `Some fun facts about me are: I studied at the University of Sydney in the fall, I played 
      hockey for 13 years, I had purple hair when I was little, and 17 kittens were born on my family farm 
      within five days
      `,
    },

    {
      name: 'VICTORIA ZHOU',
      role: 'VC Marketing',
      discipline: ' EngSci (BME) 2T5 + PEY',
      image: vcMarketing,
      description: `Some fun facts about me are: I grew up dancing and doing rhythmic gymnastics 💃 Being from 
      Alberta I love going to the mountains and hiking 🏔️ I love mac & cheese (even though I’m lactose intolerant) 
      and if you can’t find me, I’m probably in the bathroom with a nosebleed
      `,
    },
    {
      name: 'SIMONA TENCHE',
      role: 'VC Events',
      discipline: 'MSE 2T6 + PEY',
      image: vcEvents1,
      description: `Some fun facts about me are: I love cats and baking, and if you ever want to get on my good side 
      you should definitely bring me a coffee (hehe). You’ll often find me in the hhc or engstores where I work, 
      so feel free to pop in and say hi!!
      `,
    },
    {
      name: 'KATHERINE ELDER',
      role: 'VC Events',
      discipline: 'EngSci (Physics) 2T5 + PEY',
      image: vcEvents2,
      description: `Some fun facts about me are: I’ve been a leedur and DTW subcom, co-directed nocturne, I enjoy singing 
      (in choir and alone in my room), riding horses, dragon boating (go ID) and being outside. I also drink close 
      to a daily hot chocolate in the winter months.
      `,
    },
  ],
};
