export const okayToInviteToScunt = false;
export const scuntDiscord = 'https://discord.gg/3mbFPvE9j6';
export const scuntDate = 'August 28, 2024 18:00:00';
export const aboutScunt = `
  <h2>THE H!UNT</h2>
  <h3>WHAT IS SKULE™ HUNT?</h3>
  <p>
    Skule™ Hunt is the best part of F!rosh Week (we promise we aren’t biased)! It’s a chaos filled event where you and your team compete against other F!rosh to get as many points as you possibly can. Your team gains points by completing <a href='/skule-hunt-missions'>missions</a> and getting them judged by our super judgy judges (judging stations are located in the Pit). The top teams mayyyy also get something special on top of well-deserved bragging rights ;)
  </p>
   
`;
export const rules = {
  start: [
    {
      title: 'Eligibility',
      sub: 'To compete in Skule™ Hunt, one must be:',
      items: [
        'Human, or near human (Engineering Science students will be accepted).',
        'A F!rosh (2T8!).',
        'Currently enrolled within the Faculty of Applied Science and Engineering at the University of Toronto.',
        'Have officially registered for the event via F!rosh Week 2T4.',
      ],
    },
    {
      title: 'Registration',
      sub: 'The following applies for registration:',
      items: [
        'Registration will be done through the same portal as the regular F!rosh Week registration.',
        'It will conclude Tuesday night at 11:59 PM.',
        'There is an option in your registration profile to indicate your participation in the event. By default, you have been opted in. To opt out, visit the <a href="/profile-edit">profile edit</a> page.',
      ],
    },
    {
      title: 'Teams',
      sub: 'The team structure of Skule™ Hunt will be as follows:',
      items: [
        'Contestants will be assigned randomly to 1 of 10 teams to approximate fairness prior to the event.',
        'Each team will consist of 2 Skule™ Hunt Leedurs and a number of Leedurs.',
        'Each team will be identified by a number and a team name. The number will be used to identify your team during judging, and the team name will appear on the leaderboard.',
      ],
    },
    {
      title: 'Event Schedule',
      sub: 'The timing of Skule™ Hunt will be as follows:',
      items: [
        'The event shall begin on Wednesday, August 28th at 6:00 PM EST. At this time, orientation.skule.ca/skule-hunt will go live with the list. Oh yeah and the judges will rise from their upper year slumber too.',
        'The Royal Wedding will occur at approximately 10:00 PM',
        'The event will end on Wednesday, August 28th at 11:00 PM EST. There will be a 15-minute warning announcement.',
      ],
    },
    {
      title: 'Scoring Eligibility',
      sub: 'To be eligible for points a team must complete an item on the list and:',
      items: [
        'Present sufficient proof of completion of said item to a judge: photo or video documentation, a physical item, or perform the item live before the judge.<ol><li>Some items on the list will be marked to indicate that they must be completed in-person at a judging station. In this case, photo or video evidence will not suffice for gaining points. </li><li>If there is no indication, photo/video evidence will be accepted, but more points will be awarded for completion at a judging station (if it is physically possible to do so) </li></ol>',
        '<strong>***The item must be on the list.***</strong>',
        'Not be violating Federal, Provincial, or Municipal Law, or University Policy. If an item appears illegal or impossible, it is meant to illicit a creative (and legal) solution. <b>Points WILL be deducted if laws are broken or anything particularly reckless is done!</b>',
        '<strong>***The item must be on the list.***</strong>',
        'Said item must have been completed by (at minimum) one freshman (F!rosh) from said team. Items completed by Leedurs alone will be ineligible for scoring.<ol><li>Leedurs may present digital evidence to a Judge to submit an item for their team, but there must either be a F!rosh featured in the photo/video, or proof that it was sent to the Leedur by a F!rosh.</li></ol>',
        '<strong>***The item must be on the list.***</strong>',
        'It is encouraged to let Judges keep physical items brought in person as part of the Skule™ Hunt unless the items have a significant sentimental value.',
        '<strong>***The item must be on the list.***</strong>',
        'If there is any mess created by performing an item, it must be cleaned up or you will not get the points.',
        '<strong>***The item must be on the list.***</strong>',
        'The item should not bring harm to the performant, including but not limited to: physical harm, illness, inebriety, allergic reactions, vomiting, or anything else that requires the attention of Skule™ Patrol. If you believe that doing this item would cause any of this to occur to you, do not do it. However, Skule Patrol will be available, on campus, throughout the event if needed.',
        '<strong>***The item must be on the list.***</strong>',
        'Read the room. Don’t perform an item that makes anyone in the general public uncomfortable.',
        '<strong>***The item must be on the list.***</strong>',
        'Media consent must be acquired from strangers prior to all videos/photos which involve them. Additionally, do NOT share media involving strangers on social media unless they have explicitly consented to such. Do not heckle or harass anybody - ask for their consent for the item beforehand, and ask for their consent when filming as well. Be very mindful very demure, do not do anything that would make anyone uncomfortable.',
        'Alright fine, if you clear it with a particular judge beforehand, you may perform something not on the list to THAT JUDGE IN PARTICULAR as “Bring a Reward In Beneficial to Expert” for bonus points. But again ONLY if you clear it beforehand, otherwise <b>***The item must be on the list.***</b>',
      ],
    },
    {
      title: 'Judging',
      items: [
        'Items must be submitted in person to a judge, starting at 6:00 PM and ending at 11:00 PM as with the rest of the event.',
        'There will be sixteen (16) total judges for Skule™ Hunt.<ol><li>Occasionally, members of the F!rosh Week Executive Team may stand in as judges, during busy periods. </li></ol>',
        'There will be two (2) Judging Stations, one in the Pit and another on the steps of Galbraith Building. Each station will have eight (8) judges.<ol><li>Partway through the evening, the judges will swap stations.</li></ol>',
        'A maximum of three (3) items may be submitted to a single judge by one person at once. This is required both to keep traffic flowing and to eliminate biases from particular judges. <ol><li>If there is a line, the submitter must return to the back of the line upon completion of the three items. </li><li>If there is no line, the submitter must move to a different judge to submit more items. </li><li>Judges and Base Leedurs are entitled to further limit the number of items judged at once if needed, to decrease wait times. </li></ol>',
        'There will be Skule Patrollers at each station to promote safety and control traffic at each station. <ol><li>The Patrollers must be obeyed, if they get sad points will be deducted.</li></ol>',
        'The actual number of points awarded per item will be roughly based on how creative/impressive the judge thinks the item is.<ol><li>Items can be resubmitted to potentially obtain more points. The higher of the multiple submissions will be the one counted.</li><li>If you go particularly above and beyond expectations, there is a slim possibility to earn slightly above the maximum number of points for a given item.</li></ol>',
      ],
    },
    {
      title: 'Bribery',
      items: [
        'The Judges are unwavering in their tremendously unbiased and fair judging practices. That being said, you might want to check out the Judges page on the website… It is permissible to “Bring a Reward In Beneficial to Expert” for bonus points. <b>No liquid assets or anything else of substantial value</b> should be among your “Rewards”.',
      ],
    },
    {
      title: 'The Royal Wedding',
      sub: 'The Royal Wedding is the wedding to bring Godiva’s Crown and Mr. Blue & Gold together eternally. The list items for the Royal Wedding will only be accepted by the bride and groom, as well as the SubComm Chairs. The wedding items and submissions for these items will be opened starting at 9:00 PM ish. Each group that wants to claim an item must bring the participating F!rosh and the item to the bride and groom, or SubComm chairs. The wedding will take place at 10:00 PM. Only the F!rosh approved beforehand may participate in the wedding. Any F!rosh that interferes during the wedding will be removed from the scene.',
    },
    {
      title: 'Winning',
      sub: 'The group that accumulates the most points, in the absence of any obvious cheating and/or other misconduct, by the end of the night shall be declared the winners.',
    },
    {
      title: 'Skule™ Hunt Chairs',
      sub: 'Oh Hey, that’s us!',
      items: [
        'Any and all disputes will be brought before the Skule™ Hunt Chairs who are infallible - as all decisions are infallible, they are also final.',
        'The Chairs have the reserved right to deduct up to infinity points from any team that leaves a mess in the completion of Hunt Items. Teams should thus clean up their messes to avoid the humiliation and shame that comes with being singled out for leaving a mess.',
        'The Chairs have the reserved right to deduct up to infinity points from any team found to be in violation of Section 12 rules (Kidnapping) as laid out above or for breaking the law. This is only an option for situations that can be resolved to the satisfaction of all parties.',
        'The Chairs have the reserved right to disqualify teams or individuals that are found to be in irreparable breach of these rules. Furthermore, it is the Chairs’ responsibilities to bar judges that have proven to be in breach of their expected conduct.',
        'We may decide to have certain “requests” throughout the evening. Do keep in mind that we can give out as many points as we please while the judges have only so many… ',
        'Our names are Emaan and Melanie. You can probably find out more about us if you try.',
      ],
    },
    {
      title: 'Rules',
      sub: 'These rules are up to the interpretation of the Skule™ Hunt Chairs, and may be changed at any time.',
    },

    {
      title: 'Liability',
      sub: 'The University of Toronto, the Engineering Society, the Orientation Committee, and the Skule™ Hunt executive committee will not be held accountable for illegal or unethical actions performed by event participants. The same parties will also not be liable for any injuries sustained through direct or indirect participation in the event.',
      items: [],
    },
  ],
};
