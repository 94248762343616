import ashley from '../assets/judges/Ashley.png';
import ashlyn from '../assets/judges/Ashlyn.png';
import bhoovi from '../assets/judges/Bhoovi.png';
import brian from '../assets/judges/Brian.png';
import carl from '../assets/judges/Carl.png';
import cochairs from '../assets/judges/CoChairs.png';
import hamza from '../assets/judges/Hamza.png';
import jason from '../assets/judges/Jason.png';
import kiera from '../assets/judges/Kiera.png';
import LC from '../assets/judges/LC.png';
import matthew from '../assets/judges/Matthew.png';
import nafew from '../assets/judges/Nafew.png';
import nidaa from '../assets/judges/Nidaa.png';
import reid from '../assets/judges/Reid.png';
import shosh from '../assets/judges/Shosh.png';
import tobin from '../assets/judges/Tobin.png';
import tori from '../assets/judges/Tori.png';
import tudor from '../assets/judges/Tudor.png';
import will from '../assets/judges/Will.png';

export const scuntJudges = [
  {
    name: 'Co-Chairs 🧭',
    description:
      'YOOOO, it’s Emaan and Melanie!! We’re THE connoisseurs behind this wild and crazy event :) You can probably beg us for points, but since we’re assuming (unlike us) you want to keep your dignity intact - here are some potential bribes! ',
    content: [
      ' Create the backstory for Melanie’s fake frosh adventures next year (the crazier the better)',
      ' Pitch what role Emaan should have for 2t5 Frosh Week (her last one!) ',
      ' Guess a song in our “On Repeat” playlist on Spotify',
      ' Friendship bracelets for us and you -  so we match with you!',
      ' Send Emaan BeReals with as many people as possible during Skule™ Hunt (more people, more points! (bonus points if she’s in them too!))',
      ' Build Melanie an indestructible water bottle that won’t break no matter how many times she drops it (bonus points if its insulated)',
      ' A signed letter from as many Judges and HSLs as you can that says that Skule™ Hunt is their favorite event',
      ' Improve Melanie’s dogshit immune system',
      ' Make us crosswords!',
      ' Tell us your favorite parts of The Hunt <3',
    ],
    img: cochairs,
  },
  {
    name: 'Ashlyn ✏️',
    description:
      "Hello Frosh!!! I'm Ashlyn and I'm a Mech 2T5 +PEY. My hobbies include being loud, crocheting, eating, and just generally judging everyone and everything. This year I'm your Cannonball Director and Mech Board of Directors Rep. I probably won't see most of you around Skule™ this year because I'm taking a year long mental health break (on PEY), but I hope you enjoy F!rosh week and first year!",
    content: [
      ' Perform your favourite Just Dance song',
      ' Bring me one of your favourite foods or non-alcoholic drink under $5 (bonus points if it is also one of my favourite foods)',
      ' Call your parents/someone close and let me tell them I found this phone in a questionable location',
      ' A fortune + a cookie',
      ' A frosh named Ashlyn ',
      " Stickers/patches (bonus points if I don't already own it)",
      ' Something I can bring into work tomorrow and impress my boss with',
      ' Spill the tea, like actually tell me your juiciest piece of gossip.',
      ' Bring me something that would make a good table centerpiece for Cannonball.',
    ],
    img: ashlyn,
  },
  {
    name: 'Carl ✏️',
    description:
      'Heyoooooo I’m Carl (he/him), EngSci MI 2T5+PEY. Being in Machine Intelligence I’ve seen my fair share of forgotten semicolons and just laptop going brrrr. Skule™ also knows that *graphic design is my passion*. I’ve been the editor for three different Skule™ publications (F!rosh Handbook, Skule™ Agenda, Skulebook, ask me about these!). Around campus and maybe at a dinner dance, you won’t find me holding a beer but actually holding a camera. The paparazzi are scared of me since I can easily take 70 GB or over 2000 photos in one night and get them uploaded the next day for your viewing pleasure. And now more about me rapid fire: I listen to a lot of k-pop (mainly TWICE and JYP nation!) p-pop/opm and genz music (think Lyn Lapid or Laufey), I love baking, I love going to karaoke or gambling claw machine arcades with my friends, I’m a dad to a growing army of succulents, and I’m a part-time gamer playing Minecraft, Pokémon, Teamfight Tactics, Typeracer (if you wanna count that) and some rhythm games too. ',
    content: [
      ' Perform a fully choreographed Kpop dance (bonus points for live vocals)',
      ' Prove you can get a perfect score at karaoke',
      ' Make campus (or me) look aesthetic with a photo',
      ' Bring me a F!rosh Handbook 2T6 (bonus points if I signed it)',
      ' Blow the largest bubble you can (bubble gum or blowing liquid)',
      ' Beat me in typeracer!',
      ' New stickers for my water bottle (work appropriate please, limit of one (1) per F!rosh)',
    ],
    img: carl,
  },
  {
    name: 'Keira ✏️',
    description:
      "Hello, I’m Keira, a mech 2T6 doing the bioengineering minor! I am one of the head leedurs for kappa this frosh! I'm also the current captain of the UofT Equestrian Team, one of the Engineering Stores managers, and one of the mechanical leads on UTSM. In my free time, I like playing video games, singing, and baking sweet treats. ",
    content: [
      ' Make me a playlist based on vibes and vibes only',
      ' Find urself food (must be eaten creatively)',
      " Find me a weird medical case i haven't heard of (more unhinged = more points)",
      ' Opera performance',
      ' Design me a piece of merch for stores',
      ' Honse',
      ' Fresh baked sweet treat under $5',
      ' Make your case as to what the most degenerate video game is (bonus if you make a powerpoint with memes)',
    ],
    img: kiera,
  },
  {
    name: 'Shosh ✏️',
    description:
      "Hello 2T8s and welcome! I am Shosh, an Indy 2T5 +PEY who is chronically in the Pit. I am this year's Skule™ Kup director, but I am also very passionate about the Toike, the Bnad, SUDS, BnG, and more! I love Skule™, indie video games, flowers, cooking, and classic rock. I'm looking forward to meeting you all, and best of luck!",
    content: [
      " Find the OG Skule™ Kup (Hint: It's cup-shaped)",
      ' Bring me a cool, unique board/video/tabletop/other game piece I can glue to my Skup HardHat',
      ' Have a rap battle but compliment each other instead of insults  (With song, dance, and beatboxing/acapella all strongly encouraged)',
      " High-stakes Discipline Roast-Off: Loser transfers to the winner's discipline",
      ' Make me a trinket (Extra points if its out of things you scavenge in the Pit)',
      ' Patches that are quirky, different, and not like other girls',
      ' A little treat under $5 (extra points if its new, extra extra points if its rlly good)',
      ' Stageband (or you!) playing jazzy video game music (extra points if it comes with lore conspiracy theories)',
    ],
    img: shosh,
  },
  {
    name: 'Matthew ✏️',
    description:
      "I'm Matthew. 2T6, Mech, proud member of the Lady Godiva Memorial Bnad, yadda yadda. I am ethnically Vietnamese and mention it a lot. I was born and raised in the Greater Toronto Area, and mention it a lot. I spent the summer in the far North of Canada, and mention it a lot. I am overly optimistic, naive, enthusiastic, excessively agreeable, oblivious and scatterbrained. My interests include car racing (specifically NASCAR and IndyCar), pop music (following the charts such as the Canadian Hot 100), American college football (for some reason), spreadsheets (of the above three), drawing sports logos, reading Wikipedia, Minecraft, installing mods for games I don't plan to play, boasting that I met one of the creators of the YouTube show Battle for Dream Island, watching DougDoug streams, and fanfiction (ask me for recommendations or headcanons; I am very obsessive over it and keep a list, most of which are for things I have never watched or played)",
    content: [
      ' Draw the athletic teams logo or list out loud the formal full name for every US university in the Football Bowl Subdivision',
      ' Subscribe to DougDoug on YouTube or follow him on Twitch',
      ' Draw a detailed map of the Northwest Territories including all towns and highways',
      ' Get the entire pit to do the Lethal Company dance',
      ' Get a Bnad leader to play a song for me using a kazoo',
      ' Convince me why I should buy [insert car model of your choice]',
      ' Sing a J-pop song that has accrued at least 100 million streams in Japan as certified by any of RIAJ, Oricon or Billboard JAPAN ',
      ' Join the SKULE Commuters Discord (as requested by my roommate, Akshat)',
    ],
    img: matthew,
  },
  {
    name: 'Nidaa ✏️',
    description:
      'Hello, I am Nidaa, ECE2T5 + PEY (currently on my PEY). I am an international student from Lebanon. I have been involved with a bunch of Skule™ clubs during my first 3 years. I like video games , soccer, and F1.',
    content: [
      'Get me the best cookie I’ve ever had (bonus if homemade).',
      ' Beat my high score (or the highest score, depends if I get a high enough score till then) in MathActive.',
      " Bring me the ToikeOike where my name was mentioned but not as an official role (ie, can't be when I was 4th wall destroyer).",
      ' Sing a Twenty Øne Piløts song of your choice (bonus if it’s by memory).',
      ' Bring me an ECE or EngSci student (any year) with a 4.0cGPA.',
      " Make me laugh (can't touch me, so don't think about tickling). You get 3 chances.",
    ],
    img: nidaa,
  },
  {
    name: 'Tudor ✏️',
    description:
      "I'm Tudor, an ECE2T4T1+PEY (what a mouthful). 2T-OLD. I am one of the Head Leedurs for Psi this year and a felow Judgemental person. I used to be an electrical lead for Hyperloop, a podcast director for the best newpaper on campus (only one correct answer) and I sometimes dable in the occational BnG event. I'm a big scifi book reader, movie score listener and sports fanatic. I look forward to seeing you put on a show!",
    content: [
      ' MAKE me a fancy tie for the royal wedding',
      " Write and perform a new verse for Godiva's Hymn",
      ' Print "nice." to the terminal of ECF computer number 69',
      ' Do the engineering dance infront of different art sci residences (video proof required)',
      ' Find the most embarassing photo you can of me on photos.skule.ca',
      ' Recreate the commentry from one of my favorite moments in sports',
      ' Send me an epub file of your favorite book [ MUST be free ;) ]',
      ' Explain to me why the San Jose Sharks are a future Stanley Cup Champion (bonus points if you include a ppt)',
      ' m&m',
    ],
    img: tudor,
  },
  {
    name: 'Reid ✏️',
    description:
      "Hey, I'm Reid (ECE2T4+PEY)! I'm a fan of basically anything electrical, so show me your cool side projects! You can find me spending my hours designing and operating satellites with UTAT, lighting the one and only Skule Nite, and playing with Skule™ Orchestra. In my free time, I like to read scifi and nonfiction, cook random yummy things, and once in a while I'll do my schoolwork too.",
    content: [
      ' Perform your favorite number from a musical, group numbers encouraged.',
      ' Find out where in Toronto I can get a) really good mint chip ice cream or b) orange chocolate ice cream',
      ' Give me a good response to "did you know you\'re tall?',
      ' Register to vote OR get someone else to register to vote',
      ' Communicate with HERON',
      " Get me a book that I would like. Justify why I'd like it.",
      ' Tell me when the Eglington Crosstown LRT will open. pls.',
      " Take a picture of yourself with UofT's Gömböc",
    ],
    img: reid,
  },
  {
    name: 'Jason ✏️',
    description:
      'HI FROSH!! I am an EngSci 2T4 + PEY specializing in the biomedical option (yes I know, I’m old). Skule™ Hunt was my favourite part of Frosh, and I’m sooo excited to be a Judge this year. It’s also going to be my last one (very sad), so it is IMPERATIVE we make this the best Skule™ Hunt ever. I am originally from Vancouver (go Canucks!), and in my spare time I love to play anything competitive, become a better chef, and hang out with friends! Don’t hesitate to ask me anything related to EngSci, Biomed (honestly any of the options), clubs I’ve been part of, the housing and job market and secret tips to succeed at UofT!',
    content: [
      ' Find someone who can beat me at rock paper scissors (first one to ten). Max 2 tries per team',
      ' Signed letter/video from 20 Head Leeders and/or Head Skunt Leaders and 5 execs declaring that I am the best judge. Bonus points for having more than 20 (HL/HSL) or 5 execs.',
      ' My resume is struggling! Find 3 Skule clubs and convince an exec to make me an honorary member. ',
      ' Something nostalgic from my childhood era ',
      ' Get me a 24/7 VIP pass signed by a discipline club chair to a common room of your choice',
      ' Biomedical engineering merch from a company or university',
      ' A pass for a free drink from a SUDS manager',
      ' A comprehensive spreadsheet of scholarships, competitions, and events I should look out for in my final year of undergrad.',
    ],
    img: jason,
  },
  {
    name: 'Brian ✏️',
    description:
      "Hello there! I'm Brian, ECE 2T4 + PEY. This will be my last year at SKULE™ (unless…) and I will be judging in this year's Skule™ Hunt! Some things I like to do are binging shawarmas from the green food truck, yapping in the study room, and playing soccer. Find me if you want some fair judgment or to dabble in a bit of BRIBERY!",
    content: [
      ' Get the Dean or any professor to record a video of them saying "Brian Chu is the brightest student I\'ve ever met"',
      ' Perform a 30-second free-style rap on anything Skule-related',
      ' I want a piece of a cookie (chocolate flavor)',
      ' Perform cool party tricks or unusual skills in front of me',
      ' Steal (and return) a non-yellow hardhat for me! Bonus points if it is an engsoc hardhat. ',
      ' Bring a school mascot over and make them do their signature thing!',
      ' Give me some free merch from any skule club',
      ' Draw or craft something IN PURPLE ',
    ],
    img: brian,
  },
  {
    name: 'Tobin ✏️',
    description:
      "Whats up motherfuckers my name is Tobin, I'm an MSE 2T4, recovering cosplayer, and toboggan enthusiast. I have bounced around different parts of Skule™, amassing a number of hardhats, stories, and niche skills along the way. I enjoy studying metal, listening to metal, and also sticking pieces of metal in my face.",
    content: [
      ' A drawing of a cool bug (bonus points for fun facts about it)',
      ' Write an article for the next issue of the Cannon Magazine',
      ' Give me a new tattoo',
      ' An epic fantasy weapon',
      ' Paint an EngSoc officer using ketchup',
      ' Give me wood (no, not like that you sicko)',
      ' Prepare a wholesome meal for yourself and a friend',
    ],
    img: tobin,
  },
  {
    name: 'Nafew ✏️',
    description:
      "Heyyy 2T8s, I'm Nafew, a Mech 2T6, I'm super glad to be one of your judges this year. You might also know me from the summer events, and on the Discord server (I run the entire server with some other cool people, which unfortunately, puts me in the centre of discord mod jokes ), and if you don't know what I'm talking about, I'm also one of the Outreach chairs this year! I'm also a DL for Beta! I mainly love stuff that involves space (I've been in UTAT before, and I'm returning), robotics-y stuff (I've been on NeurotechUofT before), weightlifting (Athletic centre goes hard, just don't go at 5 pm) and music (particularly playing the guitar and singing). I think I have more to my personality, but I haven't really figured that out yet, I don't know, all I know is how to judge. Anyways, let's begin The Hunt!",
    content: [
      ' Decorate my shirt (I will provide supplies)',
      ' Send a cat meme to the Co-President of Operations of NeurotechUofT',
      ' Get me something to eat (preferably a protein rich snack or ice cream) that is under $5 ',
      ' In a group of people, sing a song entirely by voice, and include all elements (i.e. the singing, instrumental)',
      " Get over 50 people on a discord voice call in the F!rosh 2T4 server (double the points if it's over 100 people)",
      " Follow the other Outreach Chair's dog's Instagram page and send them a dog meme",
      " Judge another Judge's judging when they judge your item",
      ' Randomly lecture some upper years on how to succeed in first year ',
    ],
    img: nafew,
  },
  {
    name: 'Tori ✏️',
    description:
      'Hi y’all, I’m Tori and I’m an ECE 2T5. I’m Filipino (find FEAST at clubs fair!) but born and raised in Mississauga so I can count up to 2 in Tagalog. Diet coke is my preferred source of hydration, and I can bench a whopping 20 lbs. I help make thermostats for a living (wooo ecobee pey plug)',
    content: [
      ' Lobster',
      ' Make the 2023 F!rosh recap video (are you the next F!rosh videographer??)',
      ' Draw my portrait (you only get points if it’s the best I’ve gotten so far)',
      ' Best Jojo Siwa dance and impression',
      ' Reenact a scene from the titanic but base it during a different real life tragedy ',
      ' Make me cringe',
      ' Let me choose someone from your followers list and like every post on that person’s account',
      ' Plank for 69.69 seconds',
      ' Fruit loops (no milk)',
    ],
    img: tori,
  },
  {
    name: 'LC ✏️',
    description:
      "Hey y'all! My name is LC, I'm a mech 2T6 in the Energy and Solids streams. This year I'm super stoked to be the aero-structural co-lead for the UTSM design team and a co-HL for theta!  In my free time I like reading just about anything, listening to music, going to the gym, and figure skating. Fun fact about me is I'm a skating coach. ",
    content: [
      ' Find an upper year of a design team, create then teach me a line dance with said upper year ',
      ' Make me a friendship bracelet (bonus points for a cool pattern)',
      ' Recite the first 200 digits of pi (memorized)',
      ' Create then recite a sonnet dramatically and extravagantly',
      ' A home made baked good',
      ' Make me a surfboard',
      ' A recipe that has been in your family for generations (bonus points if you tell me a cool story that goes along with it)',
      ' Find 3 Skule Nite members and perform a song from a musical',
    ],
    img: LC,
  },
  {
    name: 'Hamza ✏️',
    description:
      'Ah my dudes, I’m Hamza: ECE 2T6, a proud Engsci dropout and connoisseur of city property and random crap you’d find on the side of the street. I’m taking a bit of a hiatus from design teams while I’m on PEY but I’ve been a part of RSX, UTAT and UTFR (so feel free to bore me with your naive questions). I’m a big fan of F1, UFC, rugby and tackling homeless people in Queens park. Yea anyway here are my bribes.',
    content: [
      ' Sea Monkeys (surprise me)',
      ' Homemade projectile inventions ',
      ' Convince in under a minute me why you deserve a Japanese breeding visa while playing clash royale and hopping on one foot ',
      ' Use an item that shouldn’t be used as an instrument and perform a cover of CBAT with said item (gift all of these to LGMB) ',
      ' Convince Marcus to sing Cloud9 - the song from the disney movie that no one remembers point at marcus ',
      ' Build a sex toy out of lego (points for size and comfort) ',
      ' Build me a Chastity Cage ',
      ' Convince Nooh to admit that software is cringe as fuck (point at Nooh)',
    ],
    img: hamza,
  },
  {
    name: 'Will ✏️',
    description:
      'Hey yall, my name is Will I’m a Chem 2T5 and I L O V E love industrial grade glycine and all Belzona products. I grew up in Kingston and now live in Halifax. I am a chem club and Skule™ external enjoyer and had over 120k minutes on Spotify last Year.',
    content: [
      ' A Picture of You With the Chem Club Pig',
      ' Explain in Detail the Skibbity Toilate, Speakerhead Conflict ',
      ' I would greatly benefit from some unique art for my apartment',
      ' Request to attend FYIC from the Skule™ External Director, also tell all about it.',
      ' I would love to see a dance routine to an unorthodox song',
      ' Freestyle?!?!?!',
      ' Gimme a GOOD conspiracy ',
      ' A most fantastical portrait',
    ],
    img: will,
  },
  {
    name: 'Ashley 🖥️',
    description:
      'Hi!!! I’m Ashley and I’m an ECE 2T6 + PEY minoring in AI and business. I’m one of the webmasters this year and I’m also tech team director at IEEE UofT! I like video games, listening to music, hackathons, inline skating, and drawing. ',
    content: [
      ' DM me the most brainrot reel you can find @ashleyy.leal',
      ' Get me matcha',
      ' Give me rock/metal music recs for my playlist (bonus points if it’s from a band/artist I’ve never heard of before)',
      ' Join the ieee uoft mailing list',
      ' Draw me a pretty picture of anything related to SKULE culture (materials provided)',
      ' Bring me a sticker for my laptop!',
      ' Connect with me on linkedin ',
      ' Star the frosh website github repo',
    ],
    img: ashley,
  },
];
