import FacebookIcon from '../assets/social/facebook-square-brands.svg';
import InstagramIcon from '../assets/social/instagram-brands.svg';
import TwitterIcon from '../assets/social/twitter-square-brands.svg';
import SnapchatIcon from '../assets/social/snapchat-square-brands.svg';
import YoutubeIcon from '../assets/social/youtube.svg';
import DiscordIcon from '../assets/social/discord-brands.svg';
import TikTokIcon from '../assets/social/tiktok-brands.svg';
import WhatsAppIcon from '../assets/social/whatsapp.svg';

export const socials = [
  {
    label: 'Instagram',
    link: 'https://bit.ly/froshig',
    icon: InstagramIcon,
  },
  {
    label: 'Discord',
    link: 'https://discord.com/invite/39uyG95KaU',
    icon: DiscordIcon,
  },
  /*{
    label: 'WhatsApp',
    link: 'https://chat.whatsapp.com/L5ZKvpJIOD0JtiJDU9hj1U', //outdated, needs new WhatsApp inivite for 2T4 F!ROSH
    icon: WhatsAppIcon,
  },*/
  // {
  //   label: 'YoutubeIcon',
  //   link: 'https://www.youtube.com/@skulefroshweek',
  //   icon: YoutubeIcon,
  // },
];
